import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Link = makeShortcode("Link");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "the-kernel-gaming-track",
      "style": {
        "position": "relative"
      }
    }}>{`The Kernel Gaming Track`}<a parentName="h1" {...{
        "href": "#the-kernel-gaming-track",
        "aria-label": "the kernel gaming track permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p><img parentName="p" {...{
        "src": "/images/gaming-img/gaming_track.jpg",
        "alt": "Gaming Track"
      }}></img></p>
    <p>{`The `}<strong parentName="p">{`Kernel Gaming Track`}</strong>{` has the same `}<a parentName="p" {...{
        "href": "/start/#kernel-principles"
      }}>{`Core Learning Objectives`}</a>{` as the `}<strong parentName="p">{`Kernel Learn Track`}</strong>{`, with a greater focus on those building games and other incentivized systems in Web 3. `}</p>
    <p>{`After each module in the `}<strong parentName="p">{`Learn Track`}</strong>{`, we will share a few more articles that will hopefully deepen your appreciation of the learnings, this time in a gaming context. We'll supplement these with Firesides with invited guest speakers, plus juntos among our peer mentors.`}</p>
    <h2 {...{
      "id": "structure",
      "style": {
        "position": "relative"
      }
    }}>{`Structure`}<a parentName="h2" {...{
        "href": "#structure",
        "aria-label": "structure permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Process mdxType="Process">
      <p>{`The `}<strong parentName="p">{`Core Learning`}</strong>{` will contain a brief summary of one of the topics in the Learn Track for the given week. It's intended to be a quick recap for those who didn't have the time to go through the whole module.`}</p>
      <p>{`The `}<strong parentName="p">{`Crafted Reading`}</strong>{` is a set of articles to broaden our knowledge of the `}<strong parentName="p">{`Core Learning`}</strong>{`. We hope you'll read them before each Fireside as they'll be the points of discussion.`}</p>
      <p>{`The `}<strong parentName="p">{`Curated Material`}</strong>{` will be a collection of other links that should be helpful for those who want to dive deeper. They will cover a wider range of topics related not just to the Core Learning but to the week's whole Learn Track module.`}</p>
    </Process>
    <h2 {...{
      "id": "timing",
      "style": {
        "position": "relative"
      }
    }}>{`Timing`}<a parentName="h2" {...{
        "href": "#timing",
        "aria-label": "timing permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Process mdxType="Process">
      <p>{`Firesides are held weekly on `}<strong parentName="p">{`Wednesdays 10 AM EST`}</strong>{`. `}<a parentName="p" {...{
          "href": "https://www.addevent.com/calendar/RU314582"
        }}>{`Click Here to add it to your calendar 📅`}</a></p>
      <p>{`The general structure of Firesides will be a 20-minute on-stage discussion of the `}<strong parentName="p">{`Core Learning`}</strong>{` and `}<strong parentName="p">{`Crafted Reading`}</strong>{` material, followed by a breakout activity.`}</p>
      <p>{`Throughout the course of each week, we will also have a number of smaller conversations on a variety of themes. These conversations are hosted between you and other members of your cohort, because we learn best from our own peers.`}</p>
    </Process>
    <h2 {...{
      "id": "-learning-modules",
      "style": {
        "position": "relative"
      }
    }}>{`📖 Learning Modules`}<a parentName="h2" {...{
        "href": "#-learning-modules",
        "aria-label": " learning modules permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Learn Track Module`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Gaming Track Module`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌠 `}<a parentName="td" {...{
              "href": "/learn/module-0"
            }}>{`Introduction to Kernel`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-0/"
            }}>{`Patterns and Trust`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌍 `}<a parentName="td" {...{
              "href": "/learn/module-1"
            }}>{`Ethereum's History and State`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-1/"
            }}>{`Jamming on Value`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`💰 `}<a parentName="td" {...{
              "href": "/learn/module-2"
            }}>{`A Global Financial System`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-2/"
            }}>{`The Business of Games`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`🌐 `}<a parentName="td" {...{
              "href": "/learn/module-3"
            }}>{`Take Back The Web`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-3/"
            }}>{`Freedom and Open Source`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ℹ️ `}<a parentName="td" {...{
              "href": "/learn/module-4"
            }}>{`Internet Age Institutions`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`📈 `}<a parentName="td" {...{
              "href": "/learn/module-5"
            }}>{`Tokens and Mechanism Design`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-5/"
            }}>{`Game Discoverability`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`⚖️  `}<a parentName="td" {...{
              "href": "/learn/module-6"
            }}>{`Scaling Principled Games`}</a>{` & `}<a parentName="td" {...{
              "href": "/learn/module-7"
            }}>{`The Gift`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/track-gaming/module-6/"
            }}>{`The Infinite Game`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "-a-word-of-thanks-a-game-each-week-",
      "style": {
        "position": "relative"
      }
    }}>{`🙏 A Word of Thanks, A Game Each Week 🎮`}<a parentName="h2" {...{
        "href": "#-a-word-of-thanks-a-game-each-week-",
        "aria-label": " a word of thanks a game each week  permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We look forward to having `}<a parentName="p" {...{
        "href": "/learn/module-0/#cultured-conversation"
      }}>{`meaningful conversations`}</a>{` and playing games together each week. Thank you for being part of this journey! `}</p>
    <p>{`Here's a relevant quote (and a game!) from one of my favorite game designers:`}</p>
    <blockquote>
      <Link to="https://tinyurl.com/interpret-the-game" mdxType="Link">Your interpretation of the game is more important than my intentions.</Link>
    </blockquote>
    <Link to="https://tinyurl.com/interpret-the-game" mdxType="Link">
    <Image alt="Passage" src="/images/gaming-img/passage.png" mdxType="Image" />
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      